class Print {
  constructor(button, area) {
    this.area = area;

    button.addEventListener('click', this.onClick);
    window.addEventListener('beforeprint', this.onBeforePrint);
  }

  onClick = () => {
    window.print();
  };

  onBeforePrint = () => {
    let node = this.area;
    node.classList.add('is-printable');
    while (node !== document.body) {
      node = node.parentNode;
      node.classList.add('is-printable-parent');
    }
  };
}

export default () => {
  return Array.from(document.querySelectorAll('[data-js-print]')).forEach(button => {
    const area = document.querySelector(button.dataset.jsPrint);
    if (area) {
      return new Print(button, area);
    }
    return null;
  });
};
