import axios from 'axios';
import swal from 'sweetalert';
import { HOSTING_URL } from './railsVariables.js.erb';

const MAX_RESOLUTION_JPEG = 2048;
const MAX_RESOLUTION_PNG = 1024;
const SIGNATURE_URL = '/images/signature';

const CLASS = {
  HIDDEN: 'is-hidden',
  THUMB: ['o-tile', 'c-form__image-preview'],
  SPINNER: {
    ELEMENT: ['o-spinner', 'c-form__preview-spinner'],
    DOT: 'o-spinner__dot',
  },
  REMOVE_BUTTON: 'c-form__remove-image-button',
  CONTAINER: 'c-form__image-list',
  TRIGGER: 'c-form__file-button',
  INPUT: 'c-form__file-input',
  PROCESSING: 'is-processing',
};

class ImageUploader {
  constructor(DOM) {
    this.DOM = DOM;
    if (this.hasAllRequiredDOMNodes()) {
      this.bindClickEvent();
      this.bindSelectEvent();

      this.DOM.form = this.DOM.input.form;
      this.DOM.form.dataset.currentUploads = 0;
    }
  }

  hasAllRequiredDOMNodes() {
    const nodes = Object.values(this.DOM);
    return nodes.every(Boolean);
  }

  bindClickEvent() {
    this.DOM.trigger.addEventListener('click', ev => {
      ev.preventDefault();
      this.DOM.input.disabled = false;
      this.DOM.input.click();
      this.DOM.input.disabled = true;
    });
  }

  bindSelectEvent() {
    const reader = new FileReader();
    const { input } = this.DOM;
    input.addEventListener('change', ev => {
      const availableCapacity = ImageUploader.availableCapacity(this.DOM);
      if (ev.target.files.length <= availableCapacity) {
        Array.from(ev.target.files).forEach(file => {
          this.readFileIfPossible(reader, file);
        });
      } else {
        swal({
          className: 'c-alert',
          title: 'Images quota reached',
          text: `Sorry, you are trying to upload too many images. Please select less and try again`,
          button: {
            text: 'Got it!',
          },
        });
      }
    });
  }

  readFileIfPossible(reader, file) {
    if (reader.readyState === FileReader.LOADING) {
      requestAnimationFrame(() => {
        this.readFileIfPossible(reader, file);
      });
    } else if (!this.DOM.input.accept.split(',').includes(file.type)) {
      this.DOM.input.value = '';
      swal({
        className: 'c-alert',
        title: 'File type not allowed',
        text: `Sorry, you are trying to upload a file which type is not allowed. Please select a PNG or JPEG picture.`,
        button: {
          text: 'Got it!',
        },
      });
    } else {
      // eslint-disable-next-line no-param-reassign
      reader.onload = e => {
        ImageUploader.resizeImage(e.target.result, blob => {
          const preview = this.addPreview(blob);
          ImageUploader.toggleTrigger(this.DOM);
          this.uploadImage(blob, preview)
            .then(data => {
              this.appendHiddenInputTag(data.public_id);
              // clear the file input
              this.DOM.input.value = '';
            })
            .catch(reason => {
              this.rollbackOnError(reason, preview);
            });
        });
      };
      reader.readAsDataURL(file);
    }
  }

  appendHiddenInputTag(publicId) {
    const { name } = this.DOM.input;
    const hiddenInput = document.createElement('input');
    hiddenInput.type = 'hidden';
    hiddenInput.name = name;
    hiddenInput.value = publicId;
    this.DOM.input.parentNode.append(hiddenInput);
  }

  rollbackOnError(reason, preview) {
    let message;
    try {
      message = reason.response.data.error.message;
    } catch {
      message = 'Unknown error';
    }
    swal({
      className: 'c-alert',
      title: 'Error',
      text: `${message}`,
      button: {
        text: 'Got it!',
      },
    });
    preview.remove();
    ImageUploader.toggleTrigger(this.DOM);
  }

  static removeHiddenInputTag(publicId) {
    const hiddenInput = document.querySelector(`[value="${publicId}"]`);
    const deleteInput = document.querySelector(`[id="delete_${publicId}"]`);
    if (hiddenInput) {
      hiddenInput.setAttribute('value', '');
    }
    if (deleteInput) {
      deleteInput.setAttribute('value', 'true');
    }
  }

  addPreview(blob) {
    const preview = ImageUploader.createPreview(URL.createObjectURL(blob));
    ImageUploader.addSpinner(preview);
    this.DOM.container.insertBefore(preview, this.DOM.trigger.parentNode);
    return preview;
  }

  static createPreview(src) {
    const preview = document.createElement('li');
    preview.classList.add(...CLASS.THUMB);
    preview.style.backgroundImage = `url(${src})`;
    return preview;
  }

  static hasCapacity(params) {
    return ImageUploader.availableCapacity(params) > 0;
  }

  static availableCapacity({ input, container }) {
    const current = Array.from(container.querySelectorAll('li:not(.js-exclude)')).length;
    const max = Number(input.dataset.max);
    return max - current;
  }

  static toggleTrigger(DOM) {
    const hasCapacity = ImageUploader.hasCapacity(DOM);
    DOM.trigger.parentElement.classList[hasCapacity ? 'remove' : 'add'](CLASS.HIDDEN);
  }

  static addSpinner(el) {
    const spinner = ImageUploader.createSpinner();
    el.append(spinner);
  }

  static createSpinner(dots = 6) {
    const spinner = document.createElement('div');
    spinner.classList.add(...CLASS.SPINNER.ELEMENT);
    let i = 0;
    while (i < dots) {
      const dot = document.createElement('div');
      dot.classList.add(CLASS.SPINNER.DOT);
      spinner.append(dot);
      i += 1;
    }
    return spinner;
  }

  addRemovalButton(item, publicId) {
    const removalButton = ImageUploader.createRemovalButton(publicId);
    removalButton.addEventListener('click', () => {
      ImageUploader.onPreviewRemove(removalButton, this.DOM);
    });
    item.append(removalButton);
  }

  static createRemovalButton(publicId) {
    const button = document.createElement('div');
    button.setAttribute('role', 'button');
    button.setAttribute('aria-label', 'Remove image');
    button.dataset.id = publicId;
    button.innerHTML = `<i class="material-icons" aria-hidden="true">cancel</i>`;
    button.classList.add(CLASS.REMOVE_BUTTON);
    return button;
  }

  static onPreviewRemove(button, DOM) {
    const publicId = button.dataset.id;
    ImageUploader.removeHiddenInputTag(publicId);
    button.parentElement.remove();
    ImageUploader.toggleTrigger(DOM);
  }

  uploadImage(file, preview) {
    preview.classList.add(CLASS.PROCESSING);
    this.blockSubmitting(true);
    return ImageUploader.getSignature().then(({ data }) => {
      const formData = ImageUploader.buildFormData(file, data);
      return ImageUploader.postImage(formData).then(response => {
        preview.classList.remove(CLASS.PROCESSING);
        this.addRemovalButton(preview, response.data.public_id);
        this.blockSubmitting(false);
        return response.data;
      });
    });
  }

  blockSubmitting(disable) {
    const currentUploads = parseInt(this.DOM.form.dataset.currentUploads, 10) + (disable ? 1 : -1);
    this.DOM.form.dataset.currentUploads = currentUploads;
    this.DOM.form.querySelectorAll('[type=submit]').forEach(btn => {
      btn.toggleAttribute('disabled', currentUploads > 0);
    });
  }

  static getSignature() {
    return axios.get(SIGNATURE_URL);
  }

  static postImage(formData) {
    return axios.post(HOSTING_URL, formData);
  }

  // eslint-disable-next-line camelcase
  static buildFormData(file, { signature, signed_params, api_public_key }) {
    const formData = new FormData();
    const params = Object.entries(signed_params);
    params.forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append('file', file);
    formData.append('signature', signature);
    formData.append('api_key', api_public_key);
    return formData;
  }

  static resizeImage(dataUrl, cb) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();

    img.src = dataUrl;

    img.onload = () => {
      const type = dataUrl.includes('image/jpeg') ? 'image/jpeg' : 'image/png';
      const maxEdge = type === 'image/jpeg' ? MAX_RESOLUTION_JPEG : MAX_RESOLUTION_PNG;
      const scale = Math.min(maxEdge / img.width, maxEdge / img.height, 1);
      const width = Math.floor(img.width * scale);
      const height = Math.floor(img.height * scale);
      // console.log({ scale, width, height });
      canvas.width = width;
      canvas.height = height;

      ctx.imageSmoothingQuality = 'high';
      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob(
        blob => {
          // console.log(URL.createObjectURL(blob));
          cb(blob);
        },
        type,
        0.8,
      );
    };
  }
}

function bindRemoveEvent() {
  Array.from(document.getElementsByClassName(CLASS.REMOVE_BUTTON)).forEach(button => {
    button.addEventListener('click', () => {
      const scope = button.parentElement.parentElement.parentElement;
      const trigger = scope.querySelector(`.${CLASS.TRIGGER}`);
      const input = scope.querySelector(`.${CLASS.INPUT}`);
      const container = scope.querySelector(`.${CLASS.CONTAINER}`);
      ImageUploader.onPreviewRemove(button, { trigger, input, container });
    });
  });
}

export default () => {
  const featuredDOM = {
    trigger: document.getElementById('featured-trigger'),
    input: document.getElementById('featured-input'),
    container: document.getElementById('featured-container'),
  };

  const additionalDOM = {
    trigger: document.getElementById('additional-trigger'),
    input: document.getElementById('additional-input'),
    container: document.getElementById('additional-container'),
  };

  [featuredDOM, additionalDOM].forEach(DOM => new ImageUploader(DOM));

  bindRemoveEvent();
};
