const TRIGGER = '.js-menu-trigger';
const MENU = '.js-menu';
const ACTIVE = 'is-active';
const ROOT_CLASS = 'has-menu-opened';

function init() {
  const trigger = document.querySelector(TRIGGER);
  const menu = document.querySelector(MENU);
  if (trigger && menu) {
    trigger.addEventListener('click', () => {
      trigger.classList.toggle(ACTIVE);
      menu.classList.toggle(ACTIVE);
      document.documentElement.classList.toggle(ROOT_CLASS);
    });
  }
}

export default init;
