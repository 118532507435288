import dialogPolyfill from 'dialog-polyfill';

export class Confirm {
  constructor(button = null, dialog = null, inPlace = false) {
    this.dialog = dialog;
    this.passThrough = false;
    this.button = button;

    if (this.button) {
      this.button.addEventListener('click', this.onClick, false);
    }

    if (this.dialog) {
      this.registerDialog();
    }

    if (inPlace) {
      this.dialog.showModal();
    }
  }

  onClick = ev => {
    if (this.passThrough) {
      this.passThrough = false;
      return;
    }

    ev.preventDefault();

    if (!this.dialog) {
      this.createDialog();
    }

    this.dialog.showModal();
  };

  onClose = () => {
    if (this.dialog.returnValue === 'default') {
      this.passThrough = true;
      this.button.click();
    }
  };

  createDialog = () => {
    const template = /* html */ `
        <dialog class="o-wrap c-confirm">
          <form method="dialog">
            <h1 class="o-h2 c-confirm__heading">${this.button.dataset.confirmHeading ||
              Array.from(this.button.childNodes).filter(node => node.textContent.trim() !== '')[0].textContent}</h1>
            <p>${this.button.dataset.confirm}</p>
            <menu class="c-confirm__menu">
              <button value="cancel" class="o-btn o-btn--error c-confirm__btn js-confirm-cancel">
                ${this.button.dataset.confirmNo ||
                  'No'} <i aria-hidden="true" class="o-btn__icon material-icons">close</i>
              </button>
              <button value="default" class="o-btn o-btn--success c-confirm__btn js-confirm-default">
              ${this.button.dataset.confirmYes ||
                'Yes'} <i aria-hidden="true" class="o-btn__icon material-icons">check</i>
              </button>
            </menu>
          </form>
        </dialog>
      `;

    const parseRange = document.createRange();
    parseRange.selectNode(document.body);
    [this.dialog] = parseRange.createContextualFragment(template).children;

    this.dialog.addEventListener('close', this.onClose);

    this.registerDialog();
  };

  registerDialog = () => {
    document.body.appendChild(this.dialog);
    dialogPolyfill.registerDialog(this.dialog);
  };
}

export default () => {
  Array.from(document.querySelectorAll('.js-confirm')).map(button => new Confirm(button));
  Array.from(document.querySelectorAll('.js-confirm-in-place')).map(dialog => new Confirm(null, dialog, true));
};
