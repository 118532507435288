const LAT = 'view_lat';
const LNG = 'view_lng';
const MAP = 'view_map';

function initMap() {
  if (!window.google) {
    return;
  }

  const lat = document.getElementById(LAT);
  const lng = document.getElementById(LNG);
  const mapWrapper = document.getElementById(MAP);

  if (lat && lng && mapWrapper) {
    const position = { lat: parseFloat(lat.value), lng: parseFloat(lng.value) };

    const map = new google.maps.Map(mapWrapper, {
      zoom: 15,
      center: position,
    });
    /* eslint-disable no-new */
    new google.maps.Marker({
      position,
      map,
    });
  }
}

export default initMap;
