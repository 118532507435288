const MODULE = '.js-gallery';
const PREVIEW = '.js-gallery-preview';
const THUMB = '[data-src]';

class Gallery {
  constructor(gallery) {
    this.items = Array.from(gallery.children);
    this.preview = gallery.querySelector(PREVIEW);
    this.items.forEach(item => {
      const thumb = item.querySelector(THUMB);
      if (thumb) {
        thumb.addEventListener('click', this.onClick);
      }
    });
  }

  onClick = ev => {
    const { src } = ev.currentTarget.dataset;
    if (this.preview) {
      this.preview.src = src;
    }
  };
}

export default () => {
  Array.from(document.querySelectorAll(MODULE)).map(gallery => new Gallery(gallery));
};
