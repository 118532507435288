const ALL_KEYWORDS = [
  'hamburger',
  'cheese',
  'cheetah',
  'chinese',
  'meat',
  'vegetables',
  'onion',
  'apple',
  'sauce',
  'porsche',
];

const LIST = 'c-hints';
const ITEM = 'c-hints__item';
const SELECTED = 'is-selected';
const MIN_LENGTH = 2;
const SEPARATOR = ' ';

class Search {
  static init(
    input,
    options = {
      allowMultiple: false,
      location: false,
    },
  ) {
    return new Search(input, options);
  }

  constructor(input, options) {
    this.DOM = {};
    this.DOM.input = input;
    this.DOM.list = this.createList();
    this.options = options;

    if (this.options.location) {
      if (!window.google) {
        return;
      }
      this.bindGooglePlacesAutocomplete();
    } else {
      this.selectionIndex = 0;
      this.bindInputEvents();
      this.bindFocusBlurEvents();
      this.bindKeyboardArrows();
    }
  }

  bindGooglePlacesAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.DOM.input, {
      types: ['geocode'],
      componentRestrictions: {
        country: 'us',
      },
    });
    autocomplete.setFields(['formatted_address']);
    autocomplete.addListener('place_changed', () => {
      this.DOM.input.value = this.DOM.input.value.replace(', USA', '');
    });
  }

  bindInputEvents() {
    this.DOM.input.addEventListener('input', this.updateHints);
  }

  bindFocusBlurEvents() {
    this.DOM.input.addEventListener('focus', this.updateHints);
    this.DOM.input.addEventListener('blur', () => {
      this.removeAllItems();
    });
  }

  bindKeyboardArrows() {
    this.DOM.input.addEventListener('keydown', ev => {
      if (['ArrowUp', 'ArrowDown'].includes(ev.code)) {
        ev.preventDefault();
      }
    });
    this.DOM.input.addEventListener('keyup', ev => {
      switch (ev.code) {
        case 'ArrowUp':
          this.updateSelection(-1);
          break;
        case 'ArrowDown':
          this.updateSelection(+1);
          break;
        case 'Enter':
          this.confirmSelection();
          break;
        default:
          break;
      }
    });
  }

  getLastWord() {
    const words = this.DOM.input.value.split(SEPARATOR);
    return words[words.length - 1];
  }

  addToInputValue(value) {
    const words = this.DOM.input.value.split(SEPARATOR);
    words[words.length - 1] = value;
    this.DOM.input.value = words.join(SEPARATOR);
  }

  replaceInputValue(value) {
    this.DOM.input.value = value;
  }

  updateSelection(delta) {
    let newSelection;
    const total = this.DOM.list.children.length;
    if (this.selectionIndex === 0 && delta < 0) {
      newSelection = total + delta;
    } else {
      newSelection = (this.selectionIndex + delta) % total;
    }
    this.selectionIndex = newSelection;
    this.updateHints();
  }

  confirmSelection() {
    const selectedValue = this.hints[this.selectionIndex];
    this.removeAllItems();
    if (this.options.allowMultiple) {
      this.addToInputValue(selectedValue);
    } else {
      this.replaceInputValue(selectedValue);
    }
  }

  createList() {
    const {
      DOM: { input },
    } = this;
    const list = document.createElement('ul');
    list.classList.add(LIST);
    input.parentNode.insertBefore(list, input.nextSibling);
    return list;
  }

  renderItems(collection) {
    this.removeAllItems();
    if (this.selectionIndex > collection.length - 1) {
      this.selectionIndex = 0;
    }
    collection.forEach((keyword, index) => {
      const item = document.createElement('li');
      const isSelected = index === this.selectionIndex;
      item.classList.add(ITEM);
      item.classList[isSelected ? 'add' : 'remove'](SELECTED);
      item.innerText = keyword;
      this.DOM.list.appendChild(item);
    });
  }

  removeAllItems() {
    while (this.DOM.list.firstChild) {
      this.DOM.list.firstChild.remove();
    }
  }

  showHintsFor(phrase) {
    const phraseLC = phrase.toLowerCase();
    const hints = ALL_KEYWORDS.filter(keyword => keyword.includes(phraseLC));
    this.hints = hints;
    this.renderItems(hints);
  }

  updateHints = () => {
    const val = this.getLastWord();
    if (val.length >= MIN_LENGTH) {
      this.showHintsFor(val);
    } else {
      this.removeAllItems();
    }
  };
}

export default Search;
