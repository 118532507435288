const INPUT_SEPARATORS = [' ', '-', ',', '.'];
const OUTPUT_SEPARATOR = ',';
const MAX_KEYWORD_LENGTH = 30;
const MAX_KEYWORDS = 10;

const HIDDEN = 'is-hidden';
const FOCUSED = 'is-focused';
const WRAPPER = 'c-keywords';
const LIST = 'c-keywords__list';
const ITEM = 'o-keyword';
const CLONED_INPUT = 'c-keywords__cloned-input';
const REMOVE_BUTTON = 'o-keyword__remove';
const WARNING = 'c-keywords__warning';

class Keywords {
  static init(input) {
    return new Keywords(input);
  }

  constructor(input) {
    const wrapper = document.createElement('div');
    const list = document.createElement('div');
    const clonedInput = document.createElement('input');
    const warning = document.createElement('div');

    input.classList.add(HIDDEN);
    wrapper.classList.add(WRAPPER);
    list.classList.add(LIST);
    clonedInput.classList.add(CLONED_INPUT);
    clonedInput.setAttribute('autocomplete', 'new-keyword');
    clonedInput.setAttribute('maxlength', MAX_KEYWORD_LENGTH);
    warning.classList.add(WARNING);

    input.parentNode.insertBefore(wrapper, input.nextSibling);
    wrapper.appendChild(input);
    wrapper.appendChild(list);
    wrapper.appendChild(clonedInput);
    wrapper.parentNode.appendChild(warning);

    this.DOM = {
      wrapper,
      input,
      list,
      clonedInput,
      warning,
    };
    this.keywords = [];

    this.bindFocusBlurEvents();
    this.bindInputEvents();

    // handle initial value
    this.DOM.input.value.split(OUTPUT_SEPARATOR).forEach(keyword => {
      this.addItem(keyword);
    });
    this.toggleClonedInput();
  }

  bindInputEvents() {
    const { clonedInput } = this.DOM;

    clonedInput.addEventListener('input', ev => {
      switch (ev.inputType) {
        case 'insertText':
          if (INPUT_SEPARATORS.includes(ev.data)) {
            const keyword = clonedInput.value.split(ev.data).filter(Boolean)[0];
            if (this.addItem(keyword)) {
              clonedInput.value = '';
            }
          }
          break;
        default:
          break;
      }
    });

    clonedInput.addEventListener('keydown', ev => {
      if (ev.code === 'Enter') {
        ev.stopPropagation();
        ev.preventDefault();
        const keyword = clonedInput.value;
        if (this.addItem(keyword)) {
          clonedInput.value = '';
        }
      }
    });
  }

  bindFocusBlurEvents() {
    const { clonedInput, wrapper } = this.DOM;

    wrapper.addEventListener('click', () => {
      clonedInput.focus();
      wrapper.classList.add(FOCUSED);
    });

    clonedInput.addEventListener('blur', () => {
      wrapper.classList.remove(FOCUSED);
      if (clonedInput.value) {
        const keyword = clonedInput.value.split(INPUT_SEPARATORS).filter(Boolean)[0];
        if (this.addItem(keyword)) {
          clonedInput.value = '';
        }
      }
    });
  }

  createItem(text) {
    const item = document.createElement('div');
    const removeButton = document.createElement('button');
    const label = document.createElement('span');

    label.innerText = text;
    removeButton.classList.add(REMOVE_BUTTON);
    removeButton.type = 'button';
    item.classList.add(ITEM);
    item.appendChild(label);
    item.appendChild(removeButton);

    removeButton.addEventListener('click', ev => {
      ev.preventDefault();
      this.removeItem(text);
    });

    return item;
  }

  removeItem(text) {
    this.keywords = this.keywords.filter(keyword => {
      return keyword !== text;
    });
    this.rerenderAllItems();
    this.updateInputValue();
  }

  removeLastItem() {
    const lastItem = this.keywords.pop();
    if (lastItem) {
      this.rerenderAllItems();
    }
  }

  addItem(keyword) {
    if (keyword) {
      this.DOM.warning.textContent = '';
      const k = keyword.slice(0, MAX_KEYWORD_LENGTH);
      if (k !== keyword) {
        this.DOM.warning.textContent = `Single keyword can have up to ${MAX_KEYWORD_LENGTH} characters. Your input was truncated.`;
      }
      this.renderItem(k);
      this.keywords.push(k);
      if (!this.toggleClonedInput()) {
        this.DOM.warning.textContent = `You've reached the ${MAX_KEYWORDS} keywords limit.`;
      }
      this.updateInputValue();
    }
    return true;
  }

  renderItem(keyword) {
    this.DOM.list.append(this.createItem(keyword));
  }

  rerenderAllItems() {
    while (this.DOM.list.firstChild) {
      this.DOM.list.firstChild.remove();
    }
    this.toggleClonedInput();
    this.keywords.forEach(keyword => {
      this.renderItem(keyword);
    });
  }

  updateInputValue() {
    this.DOM.input.value = this.keywords.join(OUTPUT_SEPARATOR);
  }

  toggleClonedInput() {
    if (this.keywords.length > MAX_KEYWORDS - 1) {
      this.DOM.clonedInput.style.display = 'none';
      return false;
    }
    this.DOM.clonedInput.style.display = '';
    return true;
  }
}

export default Keywords;
