const LAT_INPUT = 'location_lat';
const LNG_INPUT = 'location_lng';
const MAP_WRAPPER = 'business_location';
const LOCATION_INPUT = '.js-google-maps-autocomplete';
const NO_LOCATION_MSG = 'Enter an address';

class InputMap {
  static init() {
    if (!window.google) {
      return null;
    }
    return new InputMap();
  }

  constructor() {
    this.latInput = document.getElementById(LAT_INPUT);
    this.lngInput = document.getElementById(LNG_INPUT);
    this.mapWrapper = document.getElementById(MAP_WRAPPER);
    this.locationInput = document.querySelector(LOCATION_INPUT);

    if (this.latInput && this.lngInput && this.mapWrapper && this.locationInput) {
      const location = { lat: parseFloat(this.latInput.value), lng: parseFloat(this.lngInput.value) };
      this.initAutocomplete(location);
      this.initMap(location);
      this.initMarker(location);
    }
  }

  initAutocomplete() {
    let types = ['address'];
    if (this.locationInput.dataset.autocompleteTypes) {
      types = JSON.parse(this.locationInput.dataset.autocompleteTypes);
    }
    this.autocomplete = new google.maps.places.Autocomplete(this.locationInput, {
      types,
      componentRestrictions: {
        country: 'us',
      },
    });
    this.autocomplete.setFields(['geometry']);
    this.autocomplete.addListener('place_changed', this.onPlaceChanged);
  }

  initMap(location) {
    this.map = new google.maps.Map(this.mapWrapper, {
      center: location,
      zoom: 15,
    });
  }

  initMarker(location) {
    this.marker = new google.maps.Marker({
      position: location,
      map: this.map,
    });
    google.maps.event.addListener(this.map, 'click', this.mapClick);
  }

  mapClick = event => {
    this.pinMarker(event.latLng);
  };

  onPlaceChanged = () => {
    const place = this.autocomplete.getPlace();
    this.locationInput.value = this.locationInput.value.replace(', USA', '');
    if (place.geometry) {
      const { location } = place.geometry;
      this.centerMap(location);
      this.pinMarker(location);
    } else {
      this.locationInput.placeholder = NO_LOCATION_MSG;
    }
  };

  centerMap(location) {
    this.map.panTo(location);
    this.map.setZoom(15);
  }

  pinMarker(location) {
    this.marker.setPosition(location);
    this.setLatLngValues(location);
  }

  setLatLngValues(location) {
    this.latInput.value = location.lat();
    this.lngInput.value = location.lng();
  }
}

export default InputMap;
