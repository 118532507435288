const TRIGGER = '.js-filters-trigger';
const FILTERS = '.js-filters';
const ROOT_CLASS = 'has-filters-opened';

function init() {
  const triggers = Array.from(document.querySelectorAll(TRIGGER));
  const filters = document.querySelector(FILTERS);
  if (triggers.length && filters) {
    triggers.forEach(trigger => {
      trigger.addEventListener('click', () => {
        document.documentElement.classList.toggle(ROOT_CLASS);
      });
    });
  }
}

export default init;
