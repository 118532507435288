export default () => {
  document.addEventListener('trix-initialize', event => {
    if (event.target.hasAttribute('no-uploads')) {
      const fileTools = event.target.previousElementSibling.querySelector('[data-trix-button-group="file-tools"]');
      fileTools.style.display = 'none';
    }
  });

  document.addEventListener('trix-file-accept', event => {
    if (event.target.hasAttribute('no-uploads')) {
      event.preventDefault();
    }
  });
};
