class Disabler {
  constructor(control) {
    this.control = control;
    this.control.addEventListener('change', this.onChange);
  }

  onChange = () => {
    const p = this.control.dataset.jsDisabler;
    const textual = document.querySelectorAll(`${p} input:not([type=radio]):not([type=checkbox]), ${p} textarea`);
    const checkable = document.querySelectorAll(`${p} input[type=radio], ${p} input[type=checkbox]`);
    const selects = document.querySelectorAll(`${p} select`);
    const buttons = document.querySelectorAll(`${p} button`);

    let disable = false;

    if (this.control.nodeName === 'INPUT') {
      disable = this.control.checked;
    } else if (this.control.nodeName === 'SELECT') {
      disable = !!this.control.value;
    }

    if ('jsDisablerInverted' in this.control.dataset && JSON.parse(this.control.dataset.jsDisablerInverted)) {
      disable = !disable;
    }

    if (disable) {
      textual.forEach(control => {
        // eslint-disable-next-line no-param-reassign
        control.value = '';
        control.setAttribute('disabled', 'disabled');
      });
      checkable.forEach(control => {
        // eslint-disable-next-line no-param-reassign
        control.checked = '';
        control.setAttribute('disabled', 'disabled');
      });
      selects.forEach(control => {
        control.setAttribute('disabled', 'disabled');
        control.querySelectorAll('option').forEach(option => {
          option.removeAttribute('selected');
        });
      });
      buttons.forEach(control => {
        control.setAttribute('disabled', 'disabled');
      });
    } else {
      [...textual, ...checkable, ...selects, ...buttons].forEach(control => {
        control.removeAttribute('disabled');
      });
    }
  };
}

export default () => {
  Array.from(document.querySelectorAll('[data-js-disabler]')).map(control => new Disabler(control));
};
