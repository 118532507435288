class Share {
  constructor(btn, modal) {
    this.btn = btn;
    this.modal = modal;

    this.btn.addEventListener('click', () => {
      if (navigator.share) {
        this.nativeShare();
      } else {
        this.shareModal();
      }
    });
  }

  nativeShare = () => {
    navigator.share({
      title: this.btn.dataset.shareTitle,
      text: this.btn.dataset.shareText,
      url: this.btn.dataset.shareUrl,
    });
  };

  shareModal = () => {
    const modalId = this.btn.dataset.share;
    const modalNode = document.getElementById(modalId);
    this.modal.open({
      id: modalId,
      trigger: this.btn,
      modalNode,
    });
  };
}

export default modal => {
  return Array.from(document.querySelectorAll('[data-share]')).forEach(btn => {
    return new Share(btn, modal);
  });
};
