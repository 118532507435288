/* eslint-disable no-param-reassign */
import Handlebars from 'handlebars';
import { Confirm } from './Confirm';

const SEARCH = '[data-table-search]';
const TEMPLATE = '[data-table-template]';
const SORT = '[data-table-sort]';
const CLEAR = '[data-table-clear]';
const THEAD = 'thead';
const TBODY = 'tbody';
const TFOOT = 'tfoot';
const HIDDEN = 'u-hidden';
const EMPTY = 'no-sorting';
const PAGINATION = '[data-table-pagination]';

const INPUT_REGEX = /^[a-zA-Z0-9 ]*$/;
const PROPS_REGEX = /^[a-z0-9_, ]*$/;

function isInputSafe(str) {
  return str.match(INPUT_REGEX);
}

function isPropsStringSafe(str) {
  return str.match(PROPS_REGEX);
}

function createRows({ filteredData, DOM, perPage, offset }) {
  const tpl = `<tr>${DOM.template.innerHTML}</tr>`;
  const rowTemplate = Handlebars.compile(tpl);
  let data = filteredData;
  if (perPage != null) {
    data = filteredData.slice(offset, offset + perPage);
  }
  const html = data.map(sale => rowTemplate(sale)).join('');
  const { tbody } = DOM;
  tbody.innerHTML = html;

  // there may be confirmation dialogs required in table actions
  Array.from(tbody.querySelectorAll('.js-confirm')).map(button => new Confirm(button));
}

function manageQuantity({ filteredData, DOM }) {
  DOM.thead.classList[filteredData.length < 2 ? 'add' : 'remove'](EMPTY);
  DOM.tfoot.classList[filteredData.length ? 'add' : 'remove'](HIDDEN);
}

function getProps(DOM) {
  const propsString = DOM.search.dataset.tableSearch;
  if (propsString && isPropsStringSafe(propsString)) {
    return propsString.split(',').map(p => p.trim());
  }
  return [];
}

function filter(state) {
  const { DOM } = state;
  const data = JSON.parse(JSON.stringify(state.data)); // work on deep data copy
  const props = getProps(DOM);
  const { value } = DOM.search;
  if (!props.length) {
    return;
  }

  if (value) {
    state.filteredData = data.filter(record => {
      let result = false;
      props.forEach(prop => {
        if (
          record[prop]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        ) {
          result = true;
          record[prop] = new Handlebars.SafeString(
            Handlebars.escapeExpression(record[prop].toString()).replace(
              new RegExp(Handlebars.escapeExpression(value), 'gi'),
              '<mark>$&</mark>',
            ),
          );
        }
      });
      return result;
    });
  } else {
    state.filteredData = data;
  }
  state.offset = 0;
}

function sort(state, prop, reverse) {
  state.filteredData = state.filteredData.sort((a, b) => {
    const result = a[prop] > b[prop];
    return (reverse ? !result : result) ? 1 : -1;
  });
}

function setPaginationState(state) {
  const {
    filteredData,
    DOM: { pagination },
    perPage,
    offset,
  } = state;

  pagination.first.toggleAttribute('disabled', offset <= 0);
  pagination.prev.toggleAttribute('disabled', offset <= 0);
  pagination.next.toggleAttribute('disabled', filteredData.length <= offset + perPage);
  pagination.last.toggleAttribute('disabled', filteredData.length <= offset + perPage);
  pagination.start.textContent = offset + 1;
  pagination.end.textContent = Math.min(offset + perPage, filteredData.length);
  pagination.total.textContent = filteredData.length;
}

function onPaginationClick(state, offset) {
  state.offset = offset;

  createRows(state);
  setPaginationState(state);
}

function bindPaginationEvents(state) {
  setPaginationState(state);

  const {
    DOM: { pagination },
  } = state;
  pagination.first.addEventListener(
    'click',
    () => {
      onPaginationClick(state, 0);
    },
    { passive: true },
  );
  pagination.prev.addEventListener(
    'click',
    () => {
      onPaginationClick(state, state.offset - state.perPage);
    },
    { passive: true },
  );
  pagination.next.addEventListener(
    'click',
    () => {
      onPaginationClick(state, state.offset + state.perPage);
    },
    { passive: true },
  );
  pagination.last.addEventListener(
    'click',
    () => {
      onPaginationClick(state, Math.floor(state.filteredData.length / state.perPage) * state.perPage);
    },
    { passive: true },
  );
}

function bindSearchChangeEvent(state) {
  const { DOM } = state;
  DOM.search.addEventListener('input', () => {
    const value = DOM.search.value.toLowerCase();
    if (value) {
      DOM.clear.style.display = '';
    } else {
      DOM.clear.style.display = 'none';
    }
    if (!isInputSafe(value)) {
      return;
    }
    filter(state);
    createRows(state);
    setPaginationState(state);
    manageQuantity(state);
  });
}

function bindSearchClearEvent(state) {
  const { DOM } = state;
  DOM.clear.addEventListener('click', () => {
    DOM.search.value = '';

    const event = new Event('input', {
      bubbles: true,
      cancelable: true,
    });

    DOM.search.dispatchEvent(event);
  });
}

function resetTriggersOrder(triggers) {
  triggers.forEach(trigger => {
    delete trigger.dataset.tableSortOrder;
  });
}

function bindSortingTriggers(state) {
  const { DOM } = state;
  const triggers = Array.from(DOM.sortingTriggers);
  triggers.forEach(trigger => {
    trigger.addEventListener('click', () => {
      const prop = trigger.dataset.tableSort;
      const oldOrder = trigger.dataset.tableSortOrder;
      const newOrder = oldOrder === 'ASC' ? 'DESC' : 'ASC';
      resetTriggersOrder(triggers);
      trigger.dataset.tableSortOrder = newOrder;
      sort(state, prop, newOrder === 'DESC');
      createRows(state);
    });
  });
}

function initInScope(data, scope, perPage) {
  const state = {
    data,
    filteredData: data,
    DOM: {
      scope,
      search: scope.querySelector(SEARCH),
      clear: scope.querySelector(CLEAR),
      template: scope.querySelector(TEMPLATE),
      thead: scope.querySelector(THEAD),
      tbody: scope.querySelector(TBODY),
      tfoot: scope.querySelector(TFOOT),
      pagination: {
        container: scope.querySelector(PAGINATION),
      },
    },
    perPage,
    offset: 0,
  };

  if (state.data) {
    createRows(state);
    state.DOM.sortingTriggers = scope.querySelectorAll(SORT);
    bindSortingTriggers(state);
    manageQuantity(state);
  }

  if (state.DOM.search) {
    bindSearchChangeEvent(state);

    if (state.DOM.clear) {
      bindSearchClearEvent(state);
    }
  }

  if (state.DOM.pagination.container) {
    const { DOM } = state;
    DOM.pagination.first = DOM.pagination.container.querySelector('[class$=first]');
    DOM.pagination.prev = DOM.pagination.container.querySelector('[class$=prev]');
    DOM.pagination.next = DOM.pagination.container.querySelector('[class$=next]');
    DOM.pagination.last = DOM.pagination.container.querySelector('[class$=last]');
    DOM.pagination.start = DOM.pagination.container.querySelector('[class$=start]');
    DOM.pagination.end = DOM.pagination.container.querySelector('[class$=end]');
    DOM.pagination.total = DOM.pagination.container.querySelector('[class$=total]');

    bindPaginationEvents(state);
  }
}

function init(data, id, perPage = null) {
  const node = document.getElementById(id);
  if (node) {
    initInScope(data, node, perPage);
  }
}

export default init;

/* eslint-enable no-param-reassign */
