import { loadRecaptcha, setupRecaptcha } from './ReCAPTCHA';

const OPENING_TRIGGER = '[data-modal-open]';
const CLOSING_TRIGGER = '[data-modal-close]';
const ACTIVE_CLASS = 'is-active';
const ROOT_CLASS = 'has-active-modal';
const PAGE_CONTENT = 'page-content';

class Modal {
  constructor() {
    this.DOM = {
      pageContent: document.getElementById(PAGE_CONTENT),
      openingTriggers: document.querySelectorAll(OPENING_TRIGGER),
      closingTriggers: document.querySelectorAll(CLOSING_TRIGGER),
    };
    this.bindOpeningTriggers();
    this.bindClosingTriggers();
    this.bindEscapeKey();
    this.handlers = [];
  }

  bindOpeningTriggers() {
    Array.from(this.DOM.openingTriggers).forEach(trigger => {
      trigger.addEventListener('click', () => {
        const modalId = trigger.dataset.modalOpen;
        const modalNode = document.getElementById(modalId);
        if (modalNode) {
          this.open({
            id: modalId,
            trigger,
            modalNode,
          });
        }
      });
    });
  }

  bindClosingTriggers() {
    Array.from(this.DOM.closingTriggers).forEach(trigger => {
      trigger.addEventListener('click', () => {
        const modalId = trigger.dataset.modalClose;
        const handler = this.getHandlerById(modalId);
        this.close(handler);
      });
    });
  }

  bindEscapeKey() {
    document.addEventListener('keyup', ({ key }) => {
      if (key === 'Escape') {
        const lastHandler = this.handlers[this.handlers.length - 1];
        this.close(lastHandler);
      }
    });
  }

  open(handler) {
    handler.modalNode.classList.add(ACTIVE_CLASS);
    document.documentElement.classList.add(ROOT_CLASS);
    this.setAriaOnOpen(handler.modalNode);
    this.handlers.push(handler);

    // reCAPTCHA
    Array.from(handler.modalNode.querySelectorAll('form')).forEach((form, idx) => {
      if (idx === 0) {
        loadRecaptcha();
      }

      setupRecaptcha(form);
    });
  }

  close(handler) {
    handler.modalNode.classList.remove(ACTIVE_CLASS);
    document.documentElement.classList.remove(ROOT_CLASS);
    this.setAriaOnClose(handler.modalNode);
    this.removeHandler(handler);
    handler.trigger.focus();
  }

  getHandlerById(id) {
    return this.handlers.find(h => h.id === id);
  }

  removeHandler(handler) {
    this.handlers = this.handlers.filter(h => h.id !== handler.id);
  }

  setAriaOnOpen(modalNode) {
    modalNode.setAttribute('aria-hidden', 'false');
    if (this.DOM.pageContent) {
      this.DOM.pageContent.setAttribute('aria-hidden', 'true');
    }
  }

  setAriaOnClose(modalNode) {
    modalNode.setAttribute('aria-hidden', 'true');
    if (this.DOM.pageContent) {
      this.DOM.pageContent.removeAttribute('aria-hidden');
    }
  }
}

export default () => new Modal();
