const MODULE_ROOT = '[data-tabs]';
const TRIGGER = '[data-tab-target]';
const TRACK = '[data-track]';
const TAB = '[data-tab]';
const ACTIVE = 'is-active';

/*
 If node with that selector will be found inside the tab,
 that tab will be set as active upon initialization.
 Helpful for revealing errors, which might occur
 on any of subsequent tab
 */
const SMART_MODE_ERROR_SELECTOR = '#error_explanation';

function setActiveTrigger(trigger, DOM) {
  DOM.triggers.forEach(t => {
    t.classList.remove(ACTIVE);
  });
  trigger.classList.add(ACTIVE);
}

function setActiveTab(tab, DOM) {
  DOM.tabs.forEach(t => {
    t.classList.remove(ACTIVE);
  });
  tab.classList.add(ACTIVE);
}

function scrollToActiveTab(tab, DOM) {
  const index = DOM.tabs.indexOf(tab);
  const { track } = DOM;
  track.style.transform = `translateX(-${index * 100}%)`;
}

function setHeight(tab, DOM) {
  requestAnimationFrame(() => {
    const { track } = DOM;
    const tabBCR = tab.getBoundingClientRect();
    const { height } = tabBCR;
    track.style.height = `${height}px`;
  });
}

function activate(trigger, DOM) {
  const target = trigger.dataset.tabTarget;
  const tab = DOM.scope.querySelector(`[data-tab=${target}]`);
  setActiveTrigger(trigger, DOM);
  setActiveTab(tab, DOM);
  scrollToActiveTab(tab, DOM);
  setHeight(tab, DOM);
}

function bindClickEventToTriggers(DOM) {
  DOM.triggers.forEach(trigger => {
    trigger.addEventListener('click', () => {
      activate(trigger, DOM);
    });
  });
}

function findBestTabToActivate(DOM) {
  let bestTab = DOM.tabs[0];
  Array.from(DOM.tabs)
    .reverse()
    .forEach(tab => {
      const hasError = tab.querySelector(SMART_MODE_ERROR_SELECTOR);
      if (hasError) {
        bestTab = tab;
      } else if (window.location.hash === `#${tab.id}`) {
        bestTab = tab;
      }
    });
  return bestTab;
}

function findTriggerByTab(tabId, DOM) {
  return Array.from(DOM.triggers).find(trigger => {
    return trigger.dataset.tabTarget === tabId;
  });
}

function initInScope(scope, mode) {
  const DOM = {
    scope,
    triggers: Array.from(scope.querySelectorAll(TRIGGER)),
    tabs: Array.from(scope.querySelectorAll(TAB)),
    track: scope.querySelector(TRACK),
  };

  let trigger;
  if (mode === 'smart') {
    const bestTab = findBestTabToActivate(DOM);
    trigger = findTriggerByTab(bestTab.dataset.tab, DOM);
  } else {
    trigger = DOM.triggers[0]; // eslint-disable-line prefer-destructuring
  }
  if (trigger) {
    activate(trigger, DOM);
  }
  bindClickEventToTriggers(DOM);
}

function init() {
  const modules = document.querySelectorAll(MODULE_ROOT);
  Array.from(modules).forEach(rootNode => {
    const mode = rootNode.dataset.tabs;
    initInScope(rootNode, mode);
  });
}

export default init;
