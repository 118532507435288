class Visible {
  constructor(box) {
    this.box = box;
    this.control = document.querySelector(this.box.dataset.jsVisibleControl);
    this.value = this.box.dataset.jsVisibleValue;

    this.control.addEventListener('change', this.onChange);
    this.onChange();
  }

  onChange = () => {
    let visible = true;
    if (this.control.selectedOptions) {
      const labels = Array.from(this.control.selectedOptions).map(option => option.label);
      const values = Array.from(this.control.selectedOptions).map(option => option.value);
      visible = labels.includes(this.value) || values.includes(this.value);
    } else {
      visible = this.control.value === this.value;
    }
    this.box.style.opacity = Number(visible);
    Array.from(this.box.querySelectorAll('input, select, textarea, button')).forEach(
      visible
        ? field => {
            field.removeAttribute('disabled');
          }
        : field => {
            field.setAttribute('disabled', 'disabled');
          },
    );
  };
}

export default () => {
  Array.from(document.querySelectorAll('[data-js-visible-control]')).map(box => new Visible(box));
};
