/* eslint-disable no-param-reassign, consistent-return */

export default () => {
  const form = document.querySelector('[js-confirm-leave]');
  let showConfirmation = true;

  if (form) {
    form.addEventListener('submit', () => {
      showConfirmation = false;
    });

    window.addEventListener('beforeunload', event => {
      if (showConfirmation) {
        event.preventDefault();
        event.returnValue = '';
        return '';
      }
    });
  }
};
