/* eslint-disable no-param-reassign */
import 'intersection-observer';

class HowItWorks {
  constructor(container) {
    this.container = container;

    this.classes = {
      READY: 'is-ready',
      OFFSCREEN: 'is-offscreen',
      VISIBLE: 'is-visible',
    };

    const mqReducedMotion = window.matchMedia('(prefers-reduced-motion)');
    if (mqReducedMotion.matches) {
      return;
    }

    const observer = new IntersectionObserver(this.onIntersection, {
      threshold: 0,
    });

    Array.from(
      document.querySelectorAll(
        ['.c-how-it-works__item', '.c-how-it-works__column', '.c-how-it-works__infobox'].join(','),
      ),
    ).forEach(item => {
      item.classList.add(this.classes.OFFSCREEN);
      observer.observe(item);
    });

    this.container.classList.add(this.classes.READY);
  }

  onIntersection = (entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.remove(this.classes.OFFSCREEN);
        entry.target.classList.add(this.classes.VISIBLE);
        observer.unobserve(entry.target);
      }
    });
  };
}

export default () => {
  return Array.from(document.querySelectorAll('.c-how-it-works')).forEach(container => {
    return new HowItWorks(container);
  });
};
