import 'requestidlecallback-polyfill';

let resolve;

const recaptchaPromise = new Promise(res => {
  resolve = res;
});

let recaptchaAdded = false;

window.recaptchaLoaded = () => {
  resolve(window.grecaptcha);
};

export function loadRecaptcha() {
  if (recaptchaAdded) {
    return recaptchaPromise;
  }

  window.requestIdleCallback(
    () => {
      const script = document.createElement('script');
      script.src = 'https://www.google.com/recaptcha/api.js?onload=recaptchaLoaded&render=explicit';
      script.async = true;

      document.head.appendChild(script);
    },
    { timeout: 1000 },
  );

  recaptchaAdded = true;
  return recaptchaPromise;
}

export function setupRecaptcha(form, successCallback = null) {
  const siteKey = window.RECAPTCHA_SITE_KEY;
  const callback =
    successCallback ||
    (() => {
      form.submit();
    });

  if (!siteKey) {
    form.addEventListener('submit', e => {
      e.preventDefault();
      callback();
    });
    return;
  }

  const recaptchaContainer = document.createElement('div');
  document.body.appendChild(recaptchaContainer);

  recaptchaPromise.then(grecaptcha => {
    const recaptchaId = grecaptcha.render(recaptchaContainer, {
      sitekey: siteKey,
      size: 'invisible',
      badge: 'bottomright',
      callback: responseToken => {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.value = responseToken;
        input.name = 'g-recaptcha-response';
        form.appendChild(input);
        callback();
      },
    });

    form.addEventListener('submit', e => {
      e.preventDefault();

      recaptchaPromise.then(grecaptchaInner => {
        grecaptchaInner.execute(recaptchaId);
      });
    });
  });
}
