import 'trix/dist/trix';
import Pikaday from 'pikaday';
import Tabs from './Tabs';
import Table from './Table';
import Menu from './Menu';
import Filters from './Filters';
import ConfirmInit from './Confirm';
import Keywords from './Keywords';
import Search from './Search';
import InputMap from './InputMap';
import ViewMap from './ViewMap';
import Gallery from './Gallery';
import ImageUploader from './ImageUploader';
import Disabler from './Disabler';
import Clipboard from './Clipboard';
import Modal from './Modal';
import TrixOverrides from './TrixOverrides';
import FormLeave from './FormLeave';
import Visible from './Visible';
import Accordion from './Accordion';
import HowItWorks from './HowItWorks';
import Print from './Print';
import Share from './Share';
import { loadRecaptcha, setupRecaptcha } from './ReCAPTCHA';
import OrganizationDetailsAutocomplete from './OrganizationDetailsAutocomplete';

Tabs();
window.Table = Table;
Menu();
Filters();
OrganizationDetailsAutocomplete();
ConfirmInit();
InputMap.init();
ViewMap();
Gallery();
ImageUploader();
Disabler();
Clipboard();
const modal = Modal();
TrixOverrides();
FormLeave();
Visible();
Accordion();
HowItWorks();
Print();
Share(modal);

// Datepicker
const calendars = Array.from(document.querySelectorAll('.js-pikaday'));
calendars.map(
  field =>
    new Pikaday({
      field,
      minDate: new Date(),
      format: 'MM-DD-YYYY',
    }),
);

// Tag pills (keywords) inside the form input
const keywordInputs = Array.from(document.querySelectorAll('.js-keywords'));
keywordInputs.forEach(input => {
  Keywords.init(input);
});

// Search by keywords
const searchByKeywordsInputs = Array.from(document.querySelectorAll('.js-search-by-keywords'));
searchByKeywordsInputs.forEach(input => {
  Search.init(input, {
    allowMultiple: true,
  });
});

// Search by location
const searchByLocationInputs = Array.from(document.querySelectorAll('.js-search-by-location'));
searchByLocationInputs.forEach(input => {
  Search.init(input, {
    location: true,
  });
});

// reCAPTCHA for log in/sign up
Array.from(document.querySelectorAll('[data-tab="signin"] form, [data-tab="signup"] form')).forEach((form, idx) => {
  if (idx === 0) {
    loadRecaptcha();
  }

  setupRecaptcha(form);
});
