const NONPROFIT_TRIGGER = '.js-nonprofit-triggers';
const BUSINESS_TRIGGER = '.js-business-triggers';

function fillFields(values, role) {
  const name = document.querySelector(`#offer_${role}_organization_details_attributes_name`);
  const email = document.querySelector(`#offer_${role}_organization_details_attributes_email`);
  const phone = document.querySelector(`#offer_${role}_organization_details_attributes_phone`);
  const description = document.querySelector(`#offer_${role}_organization_details_attributes_description`);
  const website = document.querySelector(`#offer_${role}_organization_details_attributes_website`);

  name.value = values.name;
  email.value = values.email;
  phone.value = values.phone;
  description.value = values.description;
  website.value = values.website;
}

function init() {
  const nonprofitDropdown = document.querySelector(NONPROFIT_TRIGGER);
  const businessDropdown = document.querySelector(BUSINESS_TRIGGER);

  if (nonprofitDropdown && businessDropdown) {
    nonprofitDropdown.addEventListener('change', () => {
      const id = nonprofitDropdown.value;
      // eslint-disable-next-line no-undef
      const values = organizations_details[id];

      fillFields(values, 'nonprofit');
    });

    businessDropdown.addEventListener('change', () => {
      const id = businessDropdown.value;
      // eslint-disable-next-line no-undef
      const values = organizations_details[id];

      fillFields(values, 'business');
    });
  }
}

export default init;
