const CONTAINER = '[data-js-accordion]';
const TRIGGER = '[aria-controls]';
const COLLAPSED = 'is-collapsed';

function init() {
  Array.from(document.querySelectorAll(CONTAINER)).forEach(container => {
    Array.from(container.querySelectorAll(TRIGGER)).forEach((trigger, index) => {
      const pane = document.getElementById(trigger.getAttribute('aria-controls'));
      if (pane) {
        trigger.addEventListener('click', () => {
          const toggle = trigger.getAttribute('aria-expanded') !== 'true';
          trigger.setAttribute('aria-expanded', toggle ? 'true' : 'false');
          pane.classList.toggle(COLLAPSED, !toggle);
          pane.style.height = toggle ? pane.dataset.height : null;
        });

        if (index > 0) {
          trigger.setAttribute('aria-expanded', false);
          pane.classList.add(COLLAPSED);
        }
      } else {
        trigger.removeAttribute('aria-controls');
        trigger.removeAttribute('aria-expanded');
      }
    });
  });

  const onResize = () => {
    const mql = window.matchMedia('(min-width: 768px)');

    Array.from(document.querySelectorAll(CONTAINER)).forEach(container => {
      Array.from(container.querySelectorAll(TRIGGER)).forEach(trigger => {
        const pane = document.getElementById(trigger.getAttribute('aria-controls'));
        if (pane) {
          pane.dataset.height = `${pane.scrollHeight}px`;
          if (trigger.getAttribute('aria-expanded') === 'true') {
            pane.style.height = pane.dataset.height;
          }
        }

        if (mql.matches) {
          trigger.setAttribute('tabindex', '-1');
          trigger.setAttribute('disabled', 'disabled');
        } else {
          trigger.removeAttribute('tabindex', '-1');
          trigger.removeAttribute('disabled', 'disabled');
        }
      });
    });
  };

  window.addEventListener('resize', onResize);
  window.addEventListener('orientationchange', onResize);
  onResize();
}

export default init;
