import copy from 'clipboard-copy';

const TRIGGER = '[data-copy-to-clipboard-from]';
const COPIED = 'is-copied';

class Clipboard {
  constructor() {
    const triggers = document.querySelectorAll(TRIGGER);
    Array.from(triggers).forEach(trigger => {
      const targetId = trigger.dataset.copyToClipboardFrom;
      const target = document.getElementById(targetId);

      trigger.addEventListener('click', () => {
        if (target) {
          copy(target.value).then(() => {
            this.setLabel(target);
          });
        }
      });
    });
  }

  // eslint-disable-next-line class-methods-use-this
  setLabel(target) {
    if (target.classList.contains(COPIED)) {
      target.classList.remove(COPIED);
      requestAnimationFrame(() => {
        target.classList.add(COPIED);
      });
    } else {
      target.classList.add(COPIED);
    }
  }
}

export default () => new Clipboard();
